import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={"Radix Sort | A Helpful Line-by-Line Code Tutorial | Part 1"}
				subHeader={""}
				description={
					"In computer science, radix sort is a non-comparative integer sorting algorithm that sorts data with integer keys by grouping keys by the individual digits which share the same significant position and value."
				}
				keywords={[]}
				embeddedVideo={"bK_xcul2wpA"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/0bfaace8fd27a4e4dd88e51abfcfabe1/raw/89936dec3b38df92801065d2725b3f6c794c1604/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
